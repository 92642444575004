<template>
  <div class="whole-wrap">
		<div class="container box_1170">
			<div class="section-top-border">
				<h3>Gallery Video</h3>
				<div class="row gallery-item">
					<div class="col-md-6" v-for="data in list_data" :key="data.id">
						<iframe width="100%" height="250px" :src="data.url"></iframe>
					</div>

				
				</div>
			</div>







			<!-- Button trigger modal -->


			<!-- Modal -->
			<div class="modal fade" id="modalGbr" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">{{form.uraian}}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<img style="width:100%" :src="$store.state.url.URL_APP+'uploads/'+form.file" alt="">
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
					</div>
				</div>
			</div>


			<hr>
      <div class="text-center">
        <button @click="btn_prev" type="button" class="genric-btn primary-border medium">Prev</button>
        &nbsp;
        <span style="color:#444444; font-size:11px; font-weight:bold">
          {{page_first}} - {{page_last}}
        </span>
        &nbsp;
        <button @click="btn_next" type="button" class="genric-btn primary-border medium">Next</button>
      </div>
      <hr>









		</div>
    <!-- <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/slick.min.js"></script> -->
	<script type="application/javascript" defer src="./assets/js/vendor/jquery-1.12.4.min.js"></script>
    <script type="application/javascript" defer src="./assets/js/plugins.js"></script>
    <script type="application/javascript" defer src="./assets/js/main.js"></script>
	</div>
</template>

<script>
export default {
  data() {
    return {
		page: 1,

		form : {
			id : '',
			file : [],
			fileThumb : null,
			uraian : '',
			kode : '',
			postBy : '',
		},

		list_data : [],
		fileOld : '',

		page_first: 1,
		page_last: 0,


		page_limit : 6,
		cari_value: "",

    }
  },
  methods: {
    getView : function(){
        // this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_VIDEO + "view", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data)
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
        });
      },



	selectData : async function(data){
	// console.log(data)
		this.form.id = data.id;
		this.form.kode = data.kode;
		this.form.uraian = data.uraian;
		this.form.keterangan = data.keterangan;
		this.form.postBy = data.postBy

		this.form.file = data.file;


	},



	btn_prev: function() {
      if (this.page_first > 1) {
          this.page_first--;
      } else {
          this.page_first = 1;
      }
      this.getView();
    },

    btn_next: function() {
      if (this.page_first >= this.page_last) {
          this.page_first == this.page_last;
      } else {
          this.page_first++;
      }
      this.getView();
    },

    cari_data: function() {
      this.page_first = 1;
      this.getView();
    },




  },
  mounted () {
    this.getView()
  },

}
</script>

<style>

</style>